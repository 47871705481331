import React, { Suspense, useState } from 'react';

import Button from '~/components/button';
import AutocompleteAddressInput from '~/components/inputs/autocomplete-address-input/autocomplete-address-input';
import LazyLoad from '~/components/lazy-load';
import { AutocompleteTypes } from '~/components/inputs/autocomplete-address-input/autocomplete-storage';
import LazyImg from '~/components/lazy-img';
import {
  FloatingCard,
  NoNeighborsCard,
} from '~/components/neighbors/neighbors-widget-cards';
import { useNeighbors } from '~/components/neighbors/use-neighbors';
import FullWidthSection from '~/components/static/full-width-section';
import {
  DENVER_LAT,
  DENVER_LNG,
} from '~/components/static/home/neighbors-section/denver-neighbors';
import { safeLazy } from '~/utils/safe-lazy';

import styles from '~/components/static/home/neighbors-section/neighbors-section.module.scss';

const NeighborsMap = safeLazy(() =>
  import('~/components/neighbors/neighbors-map')
);

const NeighborsSection = ({ showWhySwitch = true }) => {
  const [address, setAddress] = useState(null);
  const [lawn, setLawn] = useState({
    address: {
      latitude: DENVER_LAT,
      longitude: DENVER_LNG,
      city: 'Denver',
    },
  });

  const {
    neighbors,
    radius,
    isLoading,
    status: neighborsStatus,
  } = useNeighbors(
    {
      lat: lawn?.address.latitude,
      lng: lawn?.address.longitude,
    },
    {
      usePlaceholderData: true,
    }
  );

  const handleAddressConfirmation = async () => {
    if (!address) {
      return; // Shouldn't land in this space since the button is disabled if !address
    }

    // The autocomplete gives us everything we need to pass into <NeighborsMap>, so create a pseudo-Lawn
    setLawn({
      address: {
        latitude: address.latitude,
        longitude: address.longitude,
        city: address.city,
      },
    });
  };

  return (
    <FullWidthSection
      backgroundColor="var(--light-color)"
      id="homepage-neighbors-section"
    >
      <div className={styles.neighborhoodSectionInner}>
        <div className={styles.joinText}>
          <h2>Join your Sunday neighborhood</h2>
          <p>
            Check out how many of your neighbors are already making an impact!
            Every home that switches to Sunday helps make your neighborhood a
            better place for people, pets, and the planet.
          </p>
          <div className={styles.autocompleteContainer}>
            <AutocompleteAddressInput
              label="Zip code"
              placeholder="Enter your zip code"
              type={AutocompleteTypes.ZIP_CODE}
              onSelect={(autocompleteAddress, isFromStorage) => {
                setAddress(autocompleteAddress);
                if (isFromStorage) {
                  setLawn({
                    address: autocompleteAddress,
                  });
                }
              }}
              containerClassname={styles.inputContainer}
              inputClassname={styles.input}
            />
            <Button
              onClick={handleAddressConfirmation}
              disabled={!address || isLoading}
              isLoading={isLoading}
              data-heap-id="neighbors-look-up-cta"
            >
              Look up
            </Button>
          </div>
          {neighborsStatus === 'error' && (
            <p className={styles.neighborErrorMessage}>
              There was a problem finding your neighbors. Please try again
              later.
            </p>
          )}
        </div>
        <div className={styles.mapContainer}>
          <LazyLoad>
            <Suspense fallback={null}>
              <NeighborsMap
                lat={lawn?.address?.latitude}
                lng={lawn?.address?.longitude}
                neighbors={neighbors}
                radius={radius}
                containerClassname={styles.homepageMapContainer}
                showRadius
              />
            </Suspense>
          </LazyLoad>
          {neighbors?.length && (
            <FloatingCard
              neighborCount={neighbors?.length}
              city={lawn?.address?.city}
              variant="homepage"
            />
          )}
          {!isLoading &&
            neighborsStatus !== 'error' &&
            (!neighbors || neighbors.length === 0) && <NoNeighborsCard />}
        </div>
      </div>
      {showWhySwitch && (
        <section className={styles.whySwitchSection}>
          <h3>Why your neighbors are switching to Sunday</h3>
          <div className={styles.featuresContainer}>
            <div className={styles.feature}>
              <LazyImg
                srcSet="/images/home-page/soil-test.jpg, /images/home-page/soil-test@2x.jpg 2x"
                width="185"
                height="162"
                className={styles.featureImage}
                alt="Close-up of hands holding a plastic soil test baggie filled with soil"
              />
              <div>
                <p>
                  You don't have to spend hours researching &ndash; we do the
                  plant science stuff for you
                </p>
                <hr />
                <p>You get unlimited, one-on-one support from experts</p>
              </div>
            </div>
            <div className={styles.feature}>
              <LazyImg
                srcSet="/images/home-page/dog.jpg, /images/home-page/dog@2x.jpg 2x"
                width="185"
                height="162"
                className={styles.featureImage}
                alt="A happy white-and-brown dog sits on a lawn next to a pouch of fertilizer"
              />
              <div>
                <p>
                  Our products are made with mystery-free ingredients that are
                  better for your yard &ndash; and your family
                </p>
                <hr />
                <p>
                  Everything is delivered straight to your door &ndash; for free
                </p>
              </div>
            </div>
          </div>
        </section>
      )}
    </FullWidthSection>
  );
};

export default NeighborsSection;
